import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"




const Hero = () => {
    const data = useStaticQuery(
        graphql`
        query {
            file(relativePath: {eq: "dante-back.jpg"}) {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `
    )

    const imageData = data.file.childImageSharp.fluid

    return (

<BackgroundImage Tag="section" className="h-screen bg-center bg-cover bg-slate-600 bg-blend-overlay pt-20" fluid={imageData}>
            <div className="w-full h-full flex flex-col items-center justify-center p-8">
                <span className="text-5xl md:text-6xl text-white font-light text-center">Ci prendiamo cura dei vostri amici con il cuore</span>
            </div>
        </BackgroundImage>
    )
}

export default Hero