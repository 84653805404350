import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Hero from "../components/hero"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
    <>
    <Hero></Hero>
    <Layout>
        <div className="flex flex-wrap gap-8 items-center">
            <div className="lg:w-1/3">
                <StaticImage src="../images/staff-home.jpg" className="rounded-2xl" title="Staff veterinario pisa" width={678} />
            </div>
            <div className="flex-1">
                <h1>La nostra esperienza ed i migliori strumenti per i tuoi amici animali</h1>
                <p>Una struttura moderna ed attrezzata per la prevenzione, la diagnosi e la cura degli animali ed un servizio assistenza completo anche per affrontare emergenze con la reperibilità 24h e visite a domicilio.</p>
                <p><b>Siamo a 5 minuti dal centro di Pisa.</b><br></br>
                    Siamo a cisanello dove è molto semplice raggiungerci e abbiamo un parcheggio esterno utile per far sostare anche i nostri amici animali.
                </p>
            </div>

        </div>
        <div className="mt-24">
            <h2 className="text-center">I nostri servizi</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-8">
                <div className="text-center">
                    <StaticImage src="../images/14.png" title="Laboratorio di analisi veterinarie" width={80} />
                    <h3>Laboratorio</h3>
                    <p className="text-sm">Il laboratorio veterinario è organizzato per garantire analisi ed esami per la prevenzione, la diagnosi e la cura degli animali.</p>
                    <Link to="/servizi/laboratorio" className="btn btn-primary">Più informazioni</Link>
                </div>

                <div className="text-center">
                    <StaticImage src="../images/6.png" title="Medicina interna veterinaria" width={80}/>
                    <h3>Medicina interna</h3>
                    <p className="text-sm">La medicina interna veterinaria è una branca o più semplicemente una parte molto importante della medicina veterinaria stessa.</p>
                    <Link to="/servizi/medicina-interna" className="btn btn-primary">Più informazioni</Link>
                </div>

                <div className="text-center">
                    <StaticImage src="../images/13.png" title="Chirurgia veterinaria a pisa" width={80} />
                    <h3>Chirurgia</h3>
                    <p className="text-sm">La chirurgia è parte importante del nostro lavoro. Una particolare importanza è data ad una attenta e accurata gestione dell’anestesia inalatoria e alla terapia del dolore.</p>
                    <Link to="/servizi/chirurgia" className="btn btn-primary">Più informazioni</Link>
                </div>

                <div className="text-center">
                    <StaticImage src="../images/3.png" title="Laparoscopia veterinaria" width={80} />
                    <h3>Laparoscopia</h3>
                    <p className="text-sm">La chirurgia laparoscopica da anni ha completamente rivoluzionato il mondo della chirurgia sia in medicina umana che in ambito veterinario.</p>
                    <Link to="/servizi/laparoscopia" className="btn btn-primary">Più informazioni</Link>
                </div>

                <div className="text-center">
                    <StaticImage src="../images/5.png" title="Cura di animali esotici a pisa" width={80}/>
                    <h3>Animali esotici</h3>
                    <p className="text-sm">La nostra struttura è conosciuta ed apprezzata anche per la cura degli animali esotici come rettili, furetti e piccoli mammiferi domestici.</p>
                    <Link to="/servizi/animali-esotici" className="btn btn-primary">Più informazioni</Link>
                </div>

                <div className="text-center">
                    <StaticImage src="../images/10.png" title="Odontoiatria veterinaria"  width={80}/>
                    <h3>Odontoiatria</h3>
                    <p className="text-sm">Anche per i nostri amici animali è  molto importante per il loro stesso stato di salute generale seguire una corretta igiene orale.</p>
                    <Link to="/servizi/odontoiatria" className="btn btn-primary">Più informazioni</Link>
                </div>

                <div className="text-center">
                    <StaticImage src="../images/3.png" title="Diagnostica veterinaria" width={80}/>
                    <h3>Diagnostica</h3>
                    <p className="text-sm">Nella nostra struttura veterinaria una larga parte è legata alla diagnosi così detta di immagine.</p>
                    <Link to="/servizi/diagnostica" className="btn btn-primary">Più informazioni</Link>
                </div>
            </div>
        </div>

        <div className="flex gap-8 mt-24 flex-wrap">
            <div className="text-center p-8 not-prose space-y-4 flex-1 bg-gradient-to-br from-blue-400 to-blue-500 text-white rounded-2xl shadow-xl shadow-slate-300 flex flex-col items-center justify-center">
                <span className=" block text-2xl font-light"><b>Ambulatorio</b><br></br>Tel. +39 050 575001</span>
                <span className=" block text-2xl font-light"><b>Emergenze 24h</b><br></br> +39 392 530 7631</span>
                <a href="tel:+393925307631" className="btn bg-white border-none uppercase font-bold hover:bg-white/80">Chiama ora</a>
            </div>
            <div className="lg:w-1/2">
                <StaticImage src="../images/cavallo.jpg" className="rounded-2xl" title="Animali da fattoria veterinario" width={800}/>
            </div>
        </div>

    </Layout>
    </>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
